import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import Loading from '../../components/Loading';
import withAuthentication from "../../withAuthentication";
import { Tooltip } from 'react-tooltip'
import { Dialog, DialogContent, DialogContentText, DialogTitle, Modal, Box } from "@mui/material"

function Contacts2() {

    const { userState, updateData, loading } = useContext(Context);
    const { contactsLists, currListId, contacts, currProjectId, role } = userState;

    const [contactsData, setContactsData] = useState('') // array of contacts in the current list
    const [contactsCells, setContactsCells] = useState([]) // array of contacts cells to render in the table
    // const [currList, setCurrList] = useState('') // current list of contacts
    const [currListName, setCurrListName] = useState('')
    const [questionAnalyzed, setQuestionAnalyzed] = useState('')
    const [selectedContact, setSelectedContact] = useState('') // selected contact to add to list
    const [contactsToAdd, setContactsToAdd] = useState([]) // array of contacts to add to list
    const [idDuplicated, setIdDuplicated] = useState(false)
    const [isDisabled, setDisabled] = useState(false)
    const [isModified, setIsModified] = useState(false)
    const [message, setMessage] = useState('')
    const [searchQuery, setSearchQuery] = useState('')
    const [filteredContacts, setFilteredContacts] = useState([])
    const navigate = useNavigate()

    // Modal to add contact to list:
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const modalStyle = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80vw',
        minHeight: '40vh',
        bgcolor: 'background.paper',
        border: 'none',
        boxShadow: 24,
        p: 5,
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
    };

    // Dialog to confirm if user wants to leave page with unsaved changes:
    const [route, setRoute] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    const handleDialogOpen = (route) => {
        if (isModified) {
            setOpenDialog(true);
            setRoute(route);
        } else {
            navigate(route);
        }
    }

    const handleDialogClose = (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            navigate(route);
        }
    }

    // console.log(contactsData, 'contactsData')
    // console.log(idDuplicated, 'idDuplicated')
    // console.log(isModified, 'isModified')
    // console.log(isDisabled, 'isDisabled')
    // console.log(contacts, 'contacts')
    // console.log(contactsLists, 'contactsLists')
    // console.log(currProjectId, 'currProjectId')
    // console.log(currList, 'currList')
    // console.log(contactsToAdd, 'contactsToAdd')


    // Set current list + question being analyzed + contacts
    useEffect(() => {
        if (loading || !contactsLists || !currListId) return

        // set current list name:
        const currList = contactsLists.find((list) => list.listId === currListId)
        if (!currList) return
        // setCurrList(currList)
        setCurrListName(currList.listName)

        // set question being analyzed:
        const questionAnalyzed = currList.listQuestions.find((question) => question.questionId === currProjectId)
        if (questionAnalyzed) {
            setQuestionAnalyzed(questionAnalyzed.questionText)
        }

        // set contactsData to the listData of the current list:
        setContactsData(currList.listData)
    }, [contactsLists, currListId, currProjectId, loading])

    // inside Modal, filter contacts based on search query:
    useEffect(() => {
        const filteredContacts = contacts
            .filter((contact) => {
                // remove from contacts the contacts that are already in this list:
                if (!contactsData || contactsData.length === 0) return true;
                const contactsInList = contactsData.map((contact) => contact.id);
                return !contactsInList.includes(contact.id);
            })
            .filter((contact) => {
                if (!searchQuery) return true;
                const searchTermLowerCase = searchQuery.toLowerCase();

                return (
                    (contact.fullName && contact.fullName.toLowerCase().includes(searchTermLowerCase)) ||
                    (contact.phone && contact.phone.toLowerCase().includes(searchTermLowerCase)) ||
                    (contact.email && contact.email.toLowerCase().includes(searchTermLowerCase)) ||
                    (contact.whatsapp && contact.whatsapp.toLowerCase().includes(searchTermLowerCase)) ||
                    (contact.linkedin && contact.linkedin.toLowerCase().includes(searchTermLowerCase)) ||
                    (contact.facebook && contact.facebook.toLowerCase().includes(searchTermLowerCase)) ||
                    (contact.instagram && contact.instagram.toLowerCase().includes(searchTermLowerCase)) ||
                    (contact.twitter && contact.twitter.toLowerCase().includes(searchTermLowerCase)) ||
                    (contact.keywords && contact.keywords.toLowerCase().includes(searchTermLowerCase))
                );
            });
        setFilteredContacts(filteredContacts)
    }, [searchQuery, contacts, contactsData])


    // render contacts in the table:
    useEffect(() => {
        if (loading || !contactsData) return

        const renderContacts = contactsData.map((contact, index) => {
            const contactNum = index + 1; // Calculate the contact number based on the index
            const { fullName, phone, id, email, whatsapp, twitter, linkedin, facebook, instagram, keywords } = contact;
            return (
                <tr key={id}>
                    <td>{contactNum}</td>
                    <td>{fullName}</td>
                    <td>{phone}</td>
                    <td>{email}</td>
                    <td>{whatsapp}</td>
                    <td>{linkedin}</td>
                    <td>{facebook}</td>
                    <td>{instagram}</td>
                    <td>{twitter}</td>
                    <td>{keywords}</td>
                    <td>
                        <button
                            className='transparentBtn'
                            onClick={(e) => handleDeleteContactFromList(e, id)}>
                            <i className="fa-solid fa-trash fa-ml" ></i></button>
                    </td>
                </tr>
            )
        })
        setContactsCells(renderContacts)
    }, [contactsData, loading])


    // open modal to add contact to list:
    function openAddContactModal() {
        if (loading) return
        setSelectedContact('')
        setMessage('')
        setOpen(true)
        // clear search
        setSearchQuery('')
    }

    // handle select contact to add to list:
    function handleSelectContact(id) {
        setContactsToAdd(prevContacts => {
            if (prevContacts.includes(id)) {
                return prevContacts.filter(contactId => contactId !== id);
            } else {
                return [...prevContacts, id];
            }
        });
    }
    // handle row click to select contact to add to list:
    function handleRowClick(e, id) {
        if (e.target.type !== 'checkbox') {
            handleSelectContact(id);
        }
    }

    async function addContactToList() {
        if (loading) return
        setIdDuplicated(false)
        setMessage('')
        // based on the list of contacts to add, add them to the contactsData array, 
        // we need for each user to construct a new contact object with the selected contact data:
        const newContactsData = contactsToAdd.map((contactId) => {
            const contact = contacts.find((contact) => contact.id === contactId)
            return {
                ...contact,
                analyze: [{ questionId: currProjectId, answers: '' }]
            }
        })
        // add the new contacts to the contactsData array:
        const updatedContactsData = [...contactsData, ...newContactsData]
        setContactsData(updatedContactsData)
        setContactsToAdd([])
        setIsModified(true)

        const updatedContactsLists = contactsLists.map((list) => {
            return list.listId === currListId ? { ...list, listData: updatedContactsData } : list
        })

        try {
            await updateData('contactsLists', updatedContactsLists);
            setMessage('Contacts added to list successfully')
        } catch (err) {
            console.log(err)
            setMessage('Error deleting contacts, please try again later')
        } finally {
            setTimeout(() => {
                setMessage('')
            }, 3000)
            setOpen(false)
        }


    }

    const handleDeleteContactFromList = async (e, id) => {
        if (loading) return
        e.preventDefault()
        setMessage('')

        const confirmDelete = window.confirm(`Are you sure you want to delete this contact from list "${currListName}"?`)
        if (!confirmDelete) return

        const contactInContactsArray = contacts.find((contact) => contact.id === id)
        const updatedContactsData = contactsData.filter((contact) => contact.id !== id)
        setContactsData(updatedContactsData)

        // If the contact exists in the database, delete it from there as well
        if (contactInContactsArray) {
            const updatedContactsLists = contactsLists.map((list) => {
                return list.listId === currListId ? { ...list, listData: updatedContactsData } : list
            })
            try {
                await updateData('contactsLists', updatedContactsLists);
                setMessage('Contact deleted successfully')
            } catch (err) {
                console.log(err)
                setMessage('Error deleting contact, please try again later', err)
            } finally {
                setTimeout(() => {
                    setMessage('')
                }, 3000)
            }
        }
    }

    // check if user has entered at least 2 contacts with full name
    useEffect(() => {
        if (loading || !contactsData) return
        const checkIfAtLeastOneContact = () => {
            const notEmpty = contactsData.some((contact) => {
                return contact.fullName !== ''
            })
            notEmpty ? setDisabled(false) : setDisabled(true)
        }
        checkIfAtLeastOneContact()
    }, [contactsData, loading])



    if (loading) return <Loading />

    return (
        <div className="app-home-container">
            <div className='app-title'>
                <h4>2. Add contacts to your list: "{currListName}"</h4>
                <h6><u>Add at least 2</u> and up to 20 contacts
                    who you think are the most relevant to help you with your question.</h6>
                <h6>In the next step you will analyze each one of them individually.</h6>

            </div>
            <div className='contacts2-main-container '>
                <div style={{ margin: '20px 0px', width: '100%', textAlign: 'left' }}>
                    <p>Question being analyzed:</p>
                    <div className="reminder-section ">
                        "{questionAnalyzed}"
                    </div>
                </div>
                <div className='contacts-main-container element-body'>
                    <button
                        style={{ width: '200px', marginBottom: '20px' }}
                        disabled={filteredContacts && filteredContacts.length === 0}
                        onClick={openAddContactModal}>
                        {(filteredContacts && filteredContacts.length === 0) ?
                            <>
                                Add contact to list
                                <i
                                    data-tooltip-id="contacts2-button"
                                    data-tooltip-place="right"
                                    data-tooltip-content="You have no available contacts to add to the list. You can add contacts in the previous step."
                                    className="fa fa-question-circle"
                                    aria-hidden="true"
                                ></i>
                                <Tooltip className="tooltip" id="contacts2-button" />
                            </> :
                            <>
                                Add contact to list
                                <i className='fas fa-plus-circle'></i>

                            </>
                        }
                    </button>

                    <div className='table-container'>
                        <table  >
                            <thead>
                                <tr style={{ wordBreak: 'inherit' }} >
                                    <th >#</th>
                                    <th >Full Name</th>
                                    <th ><i className="fas fa-phone"></i></th>
                                    <th><i className="fas fa-solid fa-envelope"></i></th>
                                    <th><i className="fas fa-brands fa-whatsapp"></i></th>
                                    <th><i className="fas fa-brands fa-linkedin"></i></th>
                                    <th><i className="fas fa-brands fa-facebook"></i></th>
                                    <th><i className="fas fa-brands fa-instagram"></i></th>
                                    <th><i className="fas fa-brands fa-twitter"></i></th>
                                    <th >Keywords</th>
                                    <th >Remove from list</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contactsCells && contactsCells.length > 0 ? contactsCells :
                                    <tr><td
                                        style={{
                                            padding: '40px 0',
                                            fontSize: '16px',
                                        }}
                                        colSpan="11">
                                        <p>No contacts in this list.</p>
                                        <p>To add contacts, click on the "Add contact to list" button above.</p>
                                    </td>
                                    </tr>}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div style={{ marginTop: '50px' }} className='bottomNavigation' >
                {message && <p className='successMessage'>{message}</p>}
                {role && role === 'admin' &&
                    <button
                        style={{ width: '200px' }}
                        onClick={(e) => window.print(e)}>Print / Download page
                    </button>
                }
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}  >
                    <h6>Select contacts to add to your list from the table below</h6>
                    <div className='contacts-modal-container'>

                        <input
                            type="text"
                            placeholder="Search contacts"
                            value={searchQuery || ''}
                            onChange={(e) => setSearchQuery(e.target.value)}
                            name='searchContacts'
                            className='search-input'
                            style={{ width: '250px', marginBottom: '10px' }}
                        />

                        <div className='table-container '>
                            <table >
                                <thead>
                                    <tr style={{ wordBreak: 'inherit' }} >
                                        <th>Select</th>
                                        <th >Full Name</th>
                                        <th ><i className="fas fa-phone"></i></th>
                                        <th><i className="fas fa-solid fa-envelope"></i></th>
                                        <th><i className="fas fa-brands fa-whatsapp"></i></th>
                                        <th><i className="fas fa-brands fa-linkedin"></i></th>
                                        <th><i className="fas fa-brands fa-facebook"></i></th>
                                        <th><i className="fas fa-brands fa-instagram"></i></th>
                                        <th><i className="fas fa-brands fa-twitter"></i></th>
                                        <th >Keywords</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredContacts && filteredContacts.map((contact, index) => {
                                        const { id, fullName, phone, email, whatsapp, twitter, linkedin, facebook, instagram, keywords } = contact
                                        return (
                                            <tr key={id} onClick={(e) => handleRowClick(e, id)} style={{ wordBreak: 'inherit', cursor: 'pointer' }}>
                                                <td>
                                                    <input
                                                        type="checkbox"
                                                        value={id || ''}
                                                        checked={contactsToAdd.includes(id)}
                                                        onChange={(e) => {
                                                            e.stopPropagation();
                                                            handleSelectContact(id);
                                                        }}
                                                    />
                                                </td>
                                                <td>{fullName}</td>
                                                <td>{phone}</td>
                                                <td>{email}</td>
                                                <td>{whatsapp}</td>
                                                <td>{linkedin}</td>
                                                <td>{facebook}</td>
                                                <td>{instagram}</td>
                                                <td>{twitter}</td>
                                                <td>{keywords}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div>
                        <button
                            style={{ width: '250px', margin: '20px' }}
                            disabled={contactsToAdd.length === 0}
                            onClick={addContactToList}>Add selected contacts to list
                        </button>
                        {message && <p className='successMessage'>{message}</p>}
                    </div>
                </Box>
            </Modal>


            <div className='pagination'>
                <Dialog
                    open={openDialog}
                    onClose={() => handleDialogClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className="dialog-title" id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
                    <DialogContent className="dialog-content">
                        <DialogContentText id="alert-dialog-description">
                            You have unsaved changes. Are you sure you want to leave this page?
                        </DialogContentText>
                    </DialogContent>
                    <div className="dialog-actions">
                        <button style={{ backgroundColor: 'grey' }} className="dialog-button" onClick={() => handleDialogClose(false)}>Cancel</button>
                        <button className="dialog-button" onClick={() => handleDialogClose(true)} autoFocus>Yes</button>
                    </div>
                </Dialog>
                <button onClick={() => handleDialogOpen("/ContactsApp/Contacts1")}>Back</button>
                <button onClick={() => navigate("/ContactsApp/Contacts3")} disabled={contactsData.length < 2} >Next</button>
            </div>
        </div>
    );

}

export default withAuthentication(Contacts2);