import { useState, useContext, useEffect, useMemo } from "react"
import { useNavigate } from "react-router-dom"
import { Context } from '../../Context';
import Loading from "../../components/Loading";
import withAuthentication from "../../withAuthentication";
import { Tooltip } from 'react-tooltip'
import { TextField, Dialog, DialogContent, DialogContentText, DialogTitle, Select, InputLabel, MenuItem } from "@mui/material"

function Questions2() {
    const { userState, updateData, loading } = useContext(Context);
    const { projects, currProjectId, role, questionsFromAdmin } = userState;

    const [answers, setAnswers] = useState({
        step1: {
            MainQ: '', Q1: '', Q2: '', Q3: '', Q4: '', Q5: '', Q6: '', Q7: '', Q8: '', ImprovedQ: ''
        }
    })
    const [selectedQuestionFromAdmin, setSelectedQuestionFromAdmin] = useState('')
    const [isDisabled, setDisabled] = useState(false)
    const [message, setMessage] = useState('')
    const [formErrors, setFormErrors] = useState({});
    const [isModified, setIsModified] = useState(false);
    const [mainQuestionIsEmpty, setMainQuestionIsEmpty] = useState(true);
    const [isSaving, setIsSaving] = useState(false)

    const navigate = useNavigate()

    const styles = useMemo(() => {
        return {
            inputLabelProps: {
                shrink: true,
                style: {
                    fontSize: 12,
                }
            }, inputProps: {
                style: {
                    fontSize: 15,
                }
            }
        }
    }, [])

    const eightQsTitlesStyle = {
        fontSize: 15,
        fontWeight: 'bold',
        color: '#219F8E',
        textAlign: 'left',
        marginLeft: 10,
        marginBottom: 5,
    }
    // Dialog
    const [route, setRoute] = useState("");
    const [openDialog, setOpenDialog] = useState(false);

    const handleDialogOpen = (route) => {
        const isAllFilled = checkIfAllFieldsAreFilledIn()
        if (isModified || !isAllFilled) {
            setOpenDialog(true);
            setRoute(route);
        } else {
            navigate(route);
        }
    }

    const handleDialogClose = async (confirm) => {
        setOpenDialog(false);
        if (confirm) {
            // if all project fileds are empty, delete the project before navigating:
            if (!checkIfAllFieldsAreFilledIn()) {
                console.log('all fields are empty, deleting project...')
                try {
                    const updatedProjects = projects.filter(project => project.id !== currProjectId)
                    await updateData('projects', updatedProjects)
                    await updateData('currProjectId', '')
                } catch (error) {
                    console.log(error)
                } finally {
                    navigate(route);
                }

            }
            // if only some filled in, navigate without deleting the project
            navigate(route);
        }
    }

    // check if there's data for main question, if not, set mainQuestionIsEmpty to true
    useEffect(() => {
        if (loading || !projects || !currProjectId || projects.length === 0) {
            setDisabled(true)
            return
        } else {
            const relevantProject = projects.find(project => project.id === currProjectId)
            setAnswers(relevantProject)
            setIsModified(false)
            // check if there's data for step1.MainQ, if not, set mainQuestionIsEmpty to true
            if (!relevantProject?.step1.MainQ) {
                setMainQuestionIsEmpty(true)
            } else {
                setMainQuestionIsEmpty(false)
            }
        }

    }, [projects, userState, loading, currProjectId])

    function handleChange(event) {
        const { name, value } = event.target
        setMessage('')
        setIsModified(true)
        setDisabled(true)

        if (name === 'MainQ') {
            value === '' ? setMainQuestionIsEmpty(true) : setMainQuestionIsEmpty(false)
        }


        if (name === 'MainQ-Admin') {
            setSelectedQuestionFromAdmin(value)
            setAnswers(prevQuestions => {
                return {
                    ...prevQuestions,
                    step1: {
                        ...prevQuestions.step1,
                        MainQ: value
                    }
                }
            }
            )
            return
        }

        setAnswers(prevQuestions => {
            return {
                ...prevQuestions,
                step1: {
                    ...prevQuestions.step1,
                    [name]: value
                }
            }
        })

        // If there's an error on this input, remove it when user starts typing
        if (formErrors[name]) {
            const updatedErrors = { ...formErrors };
            delete updatedErrors[name];
            setFormErrors(updatedErrors);
        }
    }

    async function saveAnswers(event) {
        event.preventDefault()
        if (!answers) return

        const isFormFilled = checkIfAllFieldsAreFilledIn()
        if (!isFormFilled) {
            setDisabled(true)
            return
        }

        const updatedProjects = projects.map((oldProject) => {
            return oldProject.id === currProjectId ? { ...oldProject, step1: answers.step1 } : oldProject;
        });

        setIsSaving(true)
        try {
            await updateData('projects', (updatedProjects))

            // if user used a question from the admin, remove it from the property of questionsFromAdmin in the user's db
            if (selectedQuestionFromAdmin) {
                const updatedQuestionsFromAdmin = questionsFromAdmin.filter(question => question.question !== selectedQuestionFromAdmin)
                await updateData('questionsFromAdmin', updatedQuestionsFromAdmin)
                setSelectedQuestionFromAdmin('')
            }
            setTimeout(() => {
                setIsSaving(false)
                setMessage('Saved successfully')
            }, 2000)

            setTimeout(() => {
                setMessage('')
                setIsModified(false)
                setDisabled(false)
            }, 2500)

        } catch (error) {
            console.log(error)
            setMessage('Error saving data', error);
            setTimeout(() => {
                setMessage('');
            }, 2000);
        }

    }

    // function to check if all fields are filled in
    function checkIfAllFieldsAreFilledIn() {
        // const step1Answers = [answers.step1.MainQ, answers.step1.Q1,
        // answers.step1.Q2, answers.step1.Q3, answers.step1.Q4, answers.step1.Q5, answers.step1.Q6,
        // answers.step1.Q7, answers.step1.Q8, answers.step1.ImprovedQ]

        const step1Answers = [answers.step1.MainQ, answers.step1.ImprovedQ]

        let errors = {};

        for (let key in step1Answers) {
            if (step1Answers[key] === '')
                errors[key] = true;
        }

        if (Object.keys(errors).length > 0) {
            setFormErrors(errors);
            alert('Please fill the main question and the improved question before saving')
            // setMessage('Please fill the main question and the improved question before saving');
            console.log('errors', errors)
            return false
        }
        return true
    }


    if (loading) return <Loading />

    return (
        <div className="app-home-container">
            <div className="app-title">
                <h4 >Step 1: your main question</h4>
                {/* <p style={{ color: '#ce6d66' }}>{!projects || projects.length === 0 ? '*Please go back to the previous step and start over' : ''}</p> */}
            </div>

            <div className='questions2-main-container'>
                <form onSubmit={saveAnswers} >
                    <div className="element-title">
                        <h5>1. Select or write your main question</h5>
                    </div>
                    <div style={{ width: '100%' }} className="element-body">
                        <InputLabel htmlFor="listSelect">Select a question sent by your manager</InputLabel>
                        <Select
                            className='questions-select'
                            name='MainQ-Admin'
                            sx={{ width: '100%', marginBottom: '20px', textAlign: 'left' }}
                            disabled={questionsFromAdmin.length === 0}
                            value={selectedQuestionFromAdmin || ''}
                            onChange={handleChange}>
                            {questionsFromAdmin && questionsFromAdmin.map((question, index) => (
                                <MenuItem key={index} value={question.question}>{question.question}</MenuItem>
                            ))
                            }
                        </Select>
                        <p>Or write your own personal question</p>
                        <TextField
                            name="MainQ"
                            value={answers ? answers?.step1.MainQ : ''}
                            onChange={handleChange}
                            variant="outlined"
                            fullWidth
                            multiline
                            rows={2}
                            disabled={selectedQuestionFromAdmin.length > 0}
                            placeholder='Example: "what is the best marketing channel?"'
                            helperText='What is the main question/objective for your upcoming interview?'
                            inputProps={{
                                autoFocus: true,
                                ...styles.inputProps
                            }}
                            InputLabelProps={styles.inputLabelProps}
                            error={formErrors.MainQ}
                        />
                    </div>

                    <div className='questions2-eight-answers-container' style={{ marginTop: '40px' }}>
                        <div className="element-title">
                            <h5>2. Fill in the 8 WH answers
                                <i
                                    data-tooltip-id="eightq-tooltip"
                                    position="right"
                                    data-tooltip-content="For each of the 8 WH questions, try filling in the info according to your main objective"
                                    className="fa fa-question-circle"
                                    aria-hidden="true"></i>
                                <Tooltip className="tooltip" id="eightq-tooltip" />
                            </h5>

                        </div>
                        <div className="questions2-eight-answers-row element-body">
                            <div>
                                <p style={eightQsTitlesStyle}>What?</p>
                                <TextField
                                    className="TextField"
                                    name="Q1"
                                    value={answers ? answers.step1.Q1 : ''}
                                    onChange={handleChange}
                                    label="Subject"
                                    multiline
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder="Example: marketing channel"
                                    helperText="Main topic"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                    error={formErrors.Q1}
                                // disabled={mainQuestionIsEmpty || !answers.step1.MainQ}
                                />
                            </div>
                            <div>
                                <p style={eightQsTitlesStyle}>When?</p>
                                <TextField
                                    className="TextField"
                                    name="Q2"
                                    value={answers ? answers.step1.Q2 : ''}
                                    onChange={handleChange}
                                    label="Timing"
                                    multiline
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder="Example: last year"
                                    helperText="Relevant timing"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                    error={formErrors.Q2}
                                // disabled={mainQuestionIsEmpty}
                                />
                            </div>
                            <div>
                                <p style={eightQsTitlesStyle}>When?</p>
                                <TextField
                                    className="TextField"
                                    name="Q3"
                                    value={answers ? answers.step1.Q3 : ''}
                                    onChange={handleChange}
                                    label="Sequence"
                                    multiline
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder="Example: after introducing the new product"
                                    helperText="Think about relevent 'before...' or 'after... '"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                    error={formErrors.Q3}
                                // disabled={mainQuestionIsEmpty}
                                />
                            </div>
                            <div>
                                <p style={eightQsTitlesStyle}>Who?</p>
                                <TextField
                                    className="TextField"
                                    name="Q4"
                                    value={answers ? answers.step1.Q4 : ''}
                                    onChange={handleChange}
                                    label="Person"
                                    multiline
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder="Example: David and his colleagues"
                                    helperText="Relevant person"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                    error={formErrors.Q4}
                                // disabled={mainQuestionIsEmpty}
                                />
                            </div>
                            <div>
                                <p style={eightQsTitlesStyle}>Who?</p>
                                <TextField
                                    className="TextField"
                                    name="Q5"
                                    value={answers ? answers.step1.Q5 : ''}
                                    onChange={handleChange}
                                    label="Entity"
                                    multiline
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder="Example: Marketing department or David’s Company"
                                    helperText="Entity involved"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                    error={formErrors.Q5}
                                // disabled={mainQuestionIsEmpty}
                                />
                            </div>
                            <div>
                                <p style={eightQsTitlesStyle}>Where?</p>
                                <TextField
                                    className="TextField"
                                    name="Q6"
                                    value={answers ? answers.step1.Q6 : ''}
                                    onChange={handleChange}
                                    label="Geography"
                                    multiline
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder='Example: Israel'
                                    helperText="Relevant location"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                    error={formErrors.Q6}
                                // disabled={mainQuestionIsEmpty}
                                />
                            </div>
                            <div>
                                <p style={eightQsTitlesStyle}>Where?</p>
                                <TextField
                                    className="TextField"
                                    name="Q7"
                                    value={answers ? answers.step1.Q7 : ''}
                                    onChange={handleChange}
                                    label="Domain"
                                    multiline
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder='Example: Marketing and Sales or Customer Service'
                                    helperText="Related domain (e.g HR, IT)"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                    error={formErrors.Q7}
                                // disabled={mainQuestionIsEmpty}
                                />
                            </div>
                            <div>
                                <p style={eightQsTitlesStyle}>Why?</p>
                                <TextField
                                    className="TextField"
                                    name="Q8"
                                    value={answers ? answers.step1.Q8 : ''}
                                    onChange={handleChange}
                                    multiline
                                    sx={{ width: '14ch' }}
                                    rows={7}
                                    placeholder="Example: decision to open a new market"
                                    helperText="Why you care about this objective"
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                    error={formErrors.Q8}
                                // disabled={mainQuestionIsEmpty}
                                />
                            </div>
                        </div>

                        <div style={{ marginTop: '40px', width: '100%' }}>
                            <div className="element-title">
                                <h5>3. Improve your original question
                                    <i
                                        data-tooltip-id="improved-tooltip"
                                        position="right"
                                        data-tooltip-content="Read your original question and see what parts from the answers above you can add to it"
                                        className="fa fa-question-circle"
                                        aria-hidden="true"></i>
                                    <Tooltip className="tooltip" id="improved-tooltip" />

                                </h5>
                            </div>
                            <div className="element-body">
                                <p className="errorMessage">{mainQuestionIsEmpty ?
                                    'Please fill in your main question to work on this section' :
                                    ''}
                                </p>
                                <TextField
                                    name="ImprovedQ"
                                    value={answers ? answers.step1.ImprovedQ : ''}
                                    onChange={handleChange}
                                    multiline
                                    fullWidth
                                    rows={5}
                                    variant="outlined"
                                    placeholder={'Example: “After introducing the new product last year in Israel as part of the process of opening new markets, will David and his colleagues be the best choice for marketing channel? “'}
                                    helperText=''
                                    inputProps={styles.inputProps}
                                    InputLabelProps={styles.inputLabelProps}
                                    error={formErrors.ImprovedQ}
                                    disabled={mainQuestionIsEmpty}
                                ></TextField>
                            </div>
                        </div>
                    </div>
                    <div className="bottomNavigation">

                        <p>Save your answers to move to the next step</p>
                        <button className="saveAnswersBtn"
                            disabled={isSaving || mainQuestionIsEmpty}
                            style={{ width: '200px' }} >Save Answers
                        </button>
                        <div className="successMessage">
                            {message ? message : ''}
                            {isSaving && (
                                <div>
                                    <i className="fas fa-spinner fa-spin"></i> Saving...
                                </div>
                            )}
                        </div>

                    </div>

                </form >
                {role && role === 'admin' &&
                    <button style={{ width: '200px' }} onClick={(e) => window.print(e)}>Print / Download page</button>
                }
            </div >

            <div className='pagination'>
                <Dialog
                    open={openDialog}
                    onClose={() => handleDialogClose(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle className="dialog-title" id="alert-dialog-title">{"Unsaved Changes"}</DialogTitle>
                    <DialogContent className="dialog-content">
                        <DialogContentText id="alert-dialog-description">
                            You have unsaved changes or forgot to fill in all fields. Are you sure you want to leave this page? All changes will be lost.
                        </DialogContentText>
                    </DialogContent>
                    <div className="dialog-actions">
                        <button style={{ backgroundColor: 'grey' }} className="dialog-button" onClick={() => handleDialogClose(false)}>Cancel</button>
                        <button className="dialog-button" onClick={() => handleDialogClose(true)} autoFocus>Yes</button>
                    </div>
                </Dialog>

                <button onClick={() => handleDialogOpen('/QuestionsApp/Questions1')}>Back to step 1</button>
                <button disabled={!answers?.step1?.MainQ || isModified} onClick={() => navigate('/QuestionsApp/Questions3')}>Next Step</button>
            </div>
        </div>
    );


}

export default withAuthentication(Questions2);
